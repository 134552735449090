import FileDropZone from '@/components/atoms/DropZone/DropZone.vue'
import ValidationAlert from '@/components/atoms/FormComponents/ValidationAlert/ValidationAlert.vue'
import { required } from 'vuelidate/lib/validators'
import { mapState } from 'vuex'

export default {
  name: 'SeafarerContactUploading',
  components: {
    ValidationAlert,
    FileDropZone
  },
  data () {
    return {
      statementAlreadyExist: false,
      buttonLoader: false
    }
  },
  computed: {
    ...mapState({
      userId: state => state.main.user.id,
      sailorId: state => state.sailor.sailorId
    }),
    mediaFilesArray () {
      return this.$refs.mediaContent.filesArray
    }
  },
  validations: {
    mediaFilesArray: { required }
  },
  mounted () {
    this.checkCrewingManagerStatement()
  },
  methods: {
    /** Check if statement is already exist */
    checkCrewingManagerStatement () {
      this.$api.get(`api/v1/crewing/${this.sailorId}/check_statement_crewing/`).then(response => {
        if ([200, 201].includes(response.code)) {
          this.statementAlreadyExist = response.data.has_statement
        }
      })
    },

    /** Check documents entries before submit */
    validationCheck () {
      if (this.$v.$invalid) {
        return this.$v.$touch()
      } else this.addSailorContract()
    },

    /** Add contact document confirmation between sailor and Morrichservice */
    addSailorContract () {
      this.buttonLoader = true
      const body = {
        manager: this.userId,
        sailor_key: this.sailorId
      }
      this.$api.post(`api/v1/crewing/statement_crew_manager_sailor/`, { body }).then(response => {
        this.buttonLoader = false
        if ([200, 201].includes(response.code)) {
          let contractDocs = new FormData()
          for (const photo of this.mediaFilesArray) {
            contractDocs.append('photo', photo)
          }
          this.$api.post(`api/v1/crewing/statement_crew_manager_sailor/${response.data.id}/upload_file/`, { body: contractDocs, responseType: 'blob' })
            .then(() => {
              this.$notification.success('createdStatement')
              this.statementAlreadyExist = true
            })
        }
      })
    }
  }
}
