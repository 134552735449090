<template lang="pug">
div.seafarerTabs.sailorProfilePage
  v-card(v-if="checkAccess('menuItem-sailor')" no-body).mb-6
    SailorMainInfo(ref="mainInfo")

  SailorContractUploading(v-if="isContractNeeded")

  v-card(v-else-if="!isContractNeeded").pa-3.mb-6
    b-tabs(fill pills)
      TabsList(
        v-if="checkAccess('tab-sailorPassport') || checkAccess('civilPassport', 'view') || checkAccess('tab-internationalPassport') || checkAccess('tab-fullNameChanges') || checkAccess('tab-sailorPassportStatement')"
        link="passports-sailors"
        icon="passports"
        countDocKey="passportAll"
        labelKey="passports"
        :tabs="tabPassport"
        :childPermissions="[checkAccess('tab-sailorPassport'), checkAccess('civilPassport', 'view'), checkAccess('tab-internationalPassport'), checkAccess('tab-fullNameChanges'), checkAccess('tab-sailorPassportStatement')]")
      TabsList(
        v-if="checkAccess('tab-education') || checkAccess('tab-student') || checkAccess('tab-educationStatement')"
        link="education-documents"
        icon="graduation"
        countDocKey="educationAll"
        labelKey="education"
        :tabs="tabEducation"
        :childPermissions="[checkAccess('tab-education'), checkAccess('tab-student'), checkAccess('tab-educationStatement')]")
      TabsList(
        v-if="checkAccess('tab-qualification') || checkAccess('tab-qualificationStatement') || checkAccess('tab-navigatorCertificates')"
        link="qualification-documents"
        icon="qualification"
        countDocKey="qualificationAll"
        labelKey="qualificationDocsTab"
        :tabs="tabQualification"
        :childPermissions="[checkAccess('tab-qualification'), checkAccess('tab-qualificationStatement'), checkAccess('tab-navigatorCertificates'), true]")
      TabsList(
        v-if="checkAccess('tab-etiCertificate') || checkAccess('tab-etiStatement')"
        link="certification-certificates"
        icon="ntzcerts"
        countDocKey="certificateAll"
        labelKey="eti"
        :tabs="tabCertification"
        :childPermissions="[checkAccess('tab-etiCertificate'), checkAccess('tab-etiStatement')]")
      TabsList(
        v-if="checkAccess('tab-serviceRecordBook') || checkAccess('tab-experience') || checkAccess('tab-statementServiceRecordBook')"
        :link="checkAccess('tab-serviceRecordBook') ? 'experience-records' : 'experience-reference'"
        icon="experience"
        countDocKey="experienceAll"
        labelKey="experience"
        :tabs="tabExperience"
        :childPermissions="[checkAccess('tab-serviceRecordBook'), checkAccess('tab-experience'), checkAccess('tab-statementServiceRecordBook')]")
      TabsList(
        v-if="checkAccess('tab-sqcProtocol') || checkAccess('tab-sqcStatement')"
        :link="checkAccess('tab-sqcStatement') ? 'sqc-statements' : 'sqc-protocols'"
        icon="dcc"
        countDocKey="sqcAll"
        labelKey="sqc"
        :tabs="tabSQC"
        :childPermissions="[checkAccess('tab-sqcStatement'), checkAccess('tab-sqcProtocol')]")
      TabsList(
        v-if="checkAccess('tab-medical') || checkAccess('tab-medicalStatement')"
        link="medical-certificates"
        icon="medical"
        countDocKey="medicalAll"
        labelKey="medical"
        :tabs="tabMedicine"
        :childPermissions="[checkAccess('tab-medical'), checkAccess('tab-medicalStatement')]")
      TabsList(
        v-if="checkAccess('tab-positionStatement')"
        link="position-statements"
        icon="positionStatement"
        countDocKey="positionStatement"
        labelKey="position")
      TabsList(
        v-if="checkAccess('tab-medical') || checkAccess('tab-medicalStatement')"
        link="digitization-documents"
        icon="digitizationDocument"
        labelKey="pendingSQC"
        :tabs="tabDigitization"
        :childPermissions="[checkAccess('tab-medical'), checkAccess('tab-medicalStatement')]")

  v-card(v-if="!isContractNeeded")
    router-view

  SailorConnections(v-if="checkAccess('menuItem-crewingManagerInfo')")
</template>

<script>
import TabsList from '@/components/atoms/TabsList.vue'
import SailorMainInfo from './SailorMainInfo/SailorMainInfo.vue'
import SailorContractUploading from './SailorContactUploading/SailorContactUploading.vue'
import SailorConnections from './SailorConnections/SailorConnections.vue'

import { mapMutations, mapState } from 'vuex'
import { checkAccess } from '@/mixins/permissions'

export default {
  components: {
    TabsList,
    SailorMainInfo,
    SailorContractUploading,
    SailorConnections
  },
  data () {
    return {
      checkAccess,
      id: String(this.$router.currentRoute.params['id']),
      tabPassport: [
        { labelKey: 'sailorPassport', countDocKey: 'passportDocument', link: 'passports-sailors' },
        { labelKey: 'civilPassport.name', countDocKey: 'passportCitizen', link: 'passports-citizen' },
        { labelKey: 'internationalPassport.name', countDocKey: 'passportInternationalDocument', link: 'passports-international' },
        { labelKey: 'surnameChanges', link: 'passports-changes', countDocKey: 'passportOldName' },
        { labelKey: 'model-StatementSailorPassport', countDocKey: 'passportStatement', link: 'passports-statements' }
      ],
      tabEducation: [
        { labelKey: 'mainDocs', countDocKey: 'educationDocument', link: 'education-documents' },
        { labelKey: 'student', countDocKey: 'studentCard', link: 'education-student' },
        { labelKey: 'advanceTrainingStatement', countDocKey: 'educationStatement', link: 'education-statements' }
      ],
      tabQualification: [
        { labelKey: 'qualificationDocs', countDocKey: 'qualificationDocument', link: 'qualification-documents' },
        { labelKey: 'statements', countDocKey: 'qualificationStatement', link: 'qualification-statements' },
        { labelKey: 'shipownerLicense', countDocKey: 'navigatorCertificate', link: 'qualification-navigator' },
        { labelKey: 'replacementStatement', countDocKey: 'qualificationReplacementStatement', link: 'qualification-replacement-statement' }
      ],
      tabCertification: [
        { labelKey: 'eti', countDocKey: 'certificateDocument', link: 'certification-certificates' },
        { labelKey: 'statements', countDocKey: 'certificateStatement', link: 'certification-statements' }
      ],
      tabExperience: [
        { labelKey: 'recordBook', countDocKey: 'recordBookDocument', link: 'experience-records' },
        { labelKey: 'internship', countDocKey: 'experienceDocument', link: 'experience-reference' },
        { labelKey: 'recordBookStatement', countDocKey: 'recordBookStatement', link: 'experience-statements' }
      ],
      tabSQC: [
        { labelKey: 'statementSQC', countDocKey: 'sqcStatement', link: 'sqc-statements' },
        { labelKey: 'protocolsSQC', countDocKey: 'sqcDocument', link: 'sqc-protocols' }
      ],
      tabMedicine: [
        { labelKey: 'certificates', countDocKey: 'medicalDocument', link: 'medical-certificates' },
        { labelKey: 'statements', countDocKey: 'medicalStatement', link: 'medical-statements' }
      ],
      tabDigitization: [
        { labelKey: 'docs', countDocKey: '', link: 'digitization-documents' },
        { labelKey: 'statements', countDocKey: '', link: 'digitization-statements' }
      ]
    }
  },
  computed: {
    ...mapState({
      isContractNeeded: state => state.main.isContractNeeded,
      isTrained: state => state.main.isTrained,
      sailorSecretaryATC: state => state.sailor.sailorSecretaryATC
    })
  },
  beforeCreate () {
    this.$store.commit('setActivePage', { name: 'home', access: true })
  },
  mounted () {
    this.getTrainingStatus()
  },
  beforeMount () {
    this.SET_STATE_SAILOR({ sailorId: this.id })
    this.$store.dispatch('getCountBadges', this.id)
    if (checkAccess('tab-sqcProtocol')) this.$store.dispatch('getSuccessProtocolSQC', { ...this.$route.params })
    this.$store.dispatch('getSuccessQualificationStatement', { ...this.$route.params })
    this.$store.dispatch('getSailorPassportProcessing', this.id)
    this.$store.dispatch('getAvailablePositions', this.id)
    this.$store.dispatch('getApprovedSailorPassportStatements', { ...this.$route.params })
    this.$store.dispatch('getExistSailorPassports', this.id)
    // Sailor Documents
    this.$store.dispatch('getSailorInformation', this.id)
  },
  methods: {
    ...mapMutations(['SET_STATE_SAILOR']),
    getTrainingStatus () {
      this.$api.get('api/v1/auth/user_is_trained/').then(response => {
        if (response?.code === 200) this.$store.commit('SET_STATE_DATA_MAIN', { type: 'isTrained', data: response.data.is_trained })
      })
    }
  }
}
</script>
