<template lang="pug">
include ../../../configs/template.pug
v-card(:loading="isCardLoading").vx-card.mt-5.text-left
  // Crewing manager information
  v-card-text.d-flex
    div(v-if="isCrewingManagerEditing").d-flex.flex-wrap.w-100
      div.w-50
        +select-validation('crewing.manager', 'crewingManagersList', 'crewingCompany', '"userFullName"', '["required"]')(
          @update:search-input="startManagersSearch" :loading="isCrewingSearching" :filter="filterObject")
          template(#item="{ item }")
            div.text-left.w-100 {{item.last_name}} {{ item.first_name }} {{ item.userprofile.middle_name || '' }} - {{ item.username }}
      div.w-50
        +date-picker-validation('crewing.date_end_proxy', 'contractDateEnd', 'dateEndObject', '["required", "minValue", "maxValue"]')(
          :min="new Date().toISOString()" max="2200-01-01")
      div.w-100.d-flex.justify-center.mt-3
        v-btn(@click="setSailorManager" :loading="isCrewingSaving" color="success") {{ $t('save') }}

    div(v-else).align-center.mr-3
      div
        span {{ $t('crewingCompany') }}: {{ getCrewingInformation() }}
        v-icon(
          v-if="checkAccess('backOffice') && sailorCrewingManager"
          @click="deactivateConnection(sailorCrewingManager)").cursor.ml-3 mdi-close
      div(v-if="sailorCrewingManager")
        div {{ $t('cooperationDate') }}: {{ getDateFormat(sailorCrewingManager.created_at) || '-' }}
        div {{ $t('cooperationDateEnd') }}: {{ getDateFormat(sailorCrewingManager.date_end_proxy) || '-' }}

    div(v-if="checkAccess('backOffice') && !sailorCrewingManager && !isCardLoading")
      v-icon(@click="isCrewingManagerEditing = !isCrewingManagerEditing").cursor {{ isCrewingManagerEditing ? 'mdi-close' : 'mdi-pen' }}

  // Advance training courses secretaty information
  v-card-text(v-if="sailorSecretaryATC && !isCrewingManagerEditing")
    span {{ $t('atcSecretary') }}: {{ getSecretaryInformation() }}
    v-icon(
      v-if="checkAccess('backOffice')"
      @click="deactivateConnection(sailorSecretaryATC)").cursor.ml-3 mdi-close
    div(v-if="typeof sailorSecretaryATC.manager_type === 'number'") {{ $t('accessSubscription') }}: {{ getDirectoryObject({ value: 'institutionTypes', id: sailorSecretaryATC.manager_type})[nameLang] }}
    div {{ $t('cooperationDate') }}: {{ getDateFormat(sailorSecretaryATC.created_at) || '-' }}
    div {{ $t('cooperationDateEnd') }}: {{ getDateFormat(sailorSecretaryATC.date_end_proxy) || '-' }}
  // Medical secretaty information
  v-card-text(v-if="sailorSecretaryMedical && !isCrewingManagerEditing")
    span {{ $t('medicalSecretary') }}: {{ getMedicalSecretaryInformation() }}
    v-icon(
      v-if="checkAccess('backOffice')"
      @click="deactivateConnection(sailorSecretaryMedical)").cursor.ml-3 mdi-close
    div {{ $t('cooperationDate') }}: {{ getDateFormat(sailorSecretaryMedical.created_at) || '-' }}
    div {{ $t('cooperationDateEnd') }}: {{ getDateFormat(sailorSecretaryMedical.date_end_proxy) || '-' }}

  v-dialog(v-model="openHistoryModal" max-width="750" scrollable)
    template(#activator="{ on }")
      v-btn(v-if="checkAccess('backOffice')" v-on="on" @click="getCrewingHistory" text).ml-1
        v-icon mdi-archive-arrow-down-outline
        span {{ $t('viewHistory') }}
    v-card(:loading="isHistoryLoading" max-height="700").modalForm
      div(@click="openHistoryModal = !openHistoryModal").modalForm-close
        v-icon mdi-close
      v-card-title.text-h5 {{ $t('history') }}
      v-card-text.text-body-1.h-100.overflow-auto
        div(v-for="(record, index) in history" :key="index")
          div - {{ $t(record.manager_type === 1 ? 'atcSecretary' : 'crewingCompany') }}. {{ record.manager_full_name }} (ID: {{ record.manager_id }}). {{ record.date_create }} - {{ getDateFormat(record.date_end) }}
</template>

<script>
import { getDateFormat } from '@/mixins/main'
import { checkAccess } from '@/mixins/permissions'
import { sailorCrewingInformation } from '@/mixins/validationRules'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  data () {
    return {
      crewing: {
        manager: null,
        date_end_proxy: null,
        manager_type: 0
      },
      history: null,
      searchTimeout: null,
      crewingManagersList: [],
      isCrewingManagerEditing: false,
      isCardLoading: false,
      isCrewingSearching: false,
      isCrewingSaving: false,
      isHistoryLoading: false,
      openHistoryModal: false,
      checkAccess,
      getDateFormat
    }
  },
  computed: {
    ...mapState({
      sailorCrewingManager: state => state.sailor.sailorCrewingManager,
      sailorSecretaryATC: state => state.sailor.sailorSecretaryATC,
      sailorSecretaryMedical: state => state.sailor.sailorSecretaryMedical,
      // crewingManagersList: state => state.user.userList
      nameLang: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr'
    }),
    ...mapGetters(['getDirectoryObject']),
    dateEndObject () {
      return this.crewing.date_end_proxy ? new Date(this.crewing.date_end_proxy) : null
    }
  },
  beforeMount () {
    this.getSailorCrewingAndSecretaryATCAndMedical()
  },
  validations () { return sailorCrewingInformation() },
  methods: {
    ...mapActions(['getCrewingAndSecretaryATC', 'setSailorOrSecretaryConnection', 'getAllUsers',
      'deactivateSailorOrSecretaryConnection', 'getCrewingAndSecretaryHistory']),

    getSailorCrewingAndSecretaryATCAndMedical () {
      this.isCardLoading = true
      this.getCrewingAndSecretaryATC().finally(() => { this.isCardLoading = false })
    },

    getCrewingInformation () {
      if (this.sailorCrewingManager && (checkAccess('backOffice') || checkAccess('support'))) {
        return `${this.sailorCrewingManager.manager.crewing_company}.
         ${this.sailorCrewingManager.manager.last_name} ${this.sailorCrewingManager.manager.first_name}
         ${this.sailorCrewingManager.manager.middle_name} (ID: ${this.sailorCrewingManager.manager.id})`
      } else if (this.sailorCrewingManager) {
        return `${this.sailorCrewingManager.manager.crewing_company} (ID: ${this.sailorCrewingManager.manager.id}).`
      } else return this.$t('missingFemale')
    },

    getSecretaryInformation () {
      if (checkAccess('backOffice') || checkAccess('support')) {
        return `${this.sailorSecretaryATC.manager.education_institution}.
         ${this.sailorSecretaryATC.manager.last_name} ${this.sailorSecretaryATC.manager.first_name}
         ${this.sailorSecretaryATC.manager.middle_name} (ID: ${this.sailorSecretaryATC.manager.id})`
      } else {
        return `${this.sailorSecretaryATC.manager.education_institution} (ID: ${this.sailorSecretaryATC.manager.id}).`
      }
    },
    getMedicalSecretaryInformation () {
      if (checkAccess('backOffice') || checkAccess('support')) {
        return `${this.sailorSecretaryMedical.manager.medical_institution}.
         ${this.sailorSecretaryMedical.manager.last_name} ${this.sailorSecretaryMedical.manager.first_name}
         ${this.sailorSecretaryMedical.manager.middle_name} (ID: ${this.sailorSecretaryMedical.manager.id})`
      } else {
        return `${this.sailorSecretaryMedical.manager.medical_institution} (ID: ${this.sailorSecretaryMedical.manager.id}).`
      }
    },

    setSailorManager () {
      if (this.$v.$invalid) return this.$v.$touch()

      this.isCrewingSaving = true
      this.setSailorOrSecretaryConnection({ body: this.crewing }).then(response => {
        if (response?.code === 201) {
          this.$notification.success('addedCrewingManager')
          this.getSailorCrewingAndSecretaryATCAndMedical()
        }
      }).finally(() => {
        this.isCrewingSaving = false
        this.isCrewingManagerEditing = false
      })
    },

    async startManagersSearch (searchQuery) {
      clearTimeout(this.searchTimeout)
      this.searchTimeout = setTimeout(async () => {
        if (!searchQuery || searchQuery.length < 3) return false
        const params = new URLSearchParams({
          page_size: 50,
          type_user: 'crewing_manager,morrichservice',
          search: searchQuery
        })
        this.isCrewingSearching = true
        const response = await this.getAllUsers(`api/v1/auth/user/?${params}`)
        if (response?.code === 200) this.crewingManagersList = response.data.results.filter(user => user.is_active)
        this.isCrewingSearching = false
      }, 1200)
    },

    deactivateConnection (manager) {
      this.$swal({
        title: manager.manager_type === 0 ? this.$t('deactivateCrewingConfirmation') : manager.manager_type === 1 ? this.$t('deactivateSecretaryConfirmation') : this.$t('deactivateMedicalSecretaryConfirmation'),
        buttons: [this.$t('no'), this.$t('yes')],
        icon: 'info',
        dangerMode: true
      }).then(confirmation => {
        if (confirmation) {
          this.isCardLoading = true
          this.deactivateSailorOrSecretaryConnection(manager.id).then(response => {
            if ([200, 204].includes(response?.code)) {
              if (manager.manager_type === 0) this.$notification.success('deactivatedCrewingManager')
              else if (manager.manager_type === 1) this.$notification.success('deactivatedSecretaryATC')
              else this.$notification.success('deactivatedMedicalSecretary')
              this.getSailorCrewingAndSecretaryATCAndMedical()
            }
          })
        }
      })
    },
    filterObject (item, queryText, itemText) {
      return (
        item.username.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.userFullName.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.id.toString().toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      )
    },
    getCrewingHistory () {
      this.isHistoryLoading = true
      this.getCrewingAndSecretaryHistory().then(response => {
        if (response?.code === 200) this.history = response.data
      }).finally(() => { this.isHistoryLoading = false })
    }
  }
}
</script>
